<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;max-width:1300px;width:100%;">
          <h1>My Account</h1>
          <div  v-if="browserFrom !== 'app'"><el-link type="primary" style="font-size:16px;" @click="logoutClick">Log out</el-link></div>
          <div style="display:block;text-align:left;">
              <div style="margin-top:40px;">
                  <h1>Order History</h1>
                  <div> 
                    <el-link href="/orders?status=paid" style="text-decoration:underline;">Paid</el-link>
                    <span style="font-size:14px;margin-left:5px;">({{paid}})</span>
                  </div>
                  <div style="margin-top:10px;"> 
                    <el-link href="/orders?status=delivered" style="text-decoration:underline;">Delivered</el-link>
                    <span style="font-size:14px;margin-left:5px;">({{delivered}})</span>
                  </div>
                  <!--<div style="margin-top:10px;"> 
                    <el-link href="/orders?status=commented" style="text-decoration:underline;">Reviewed</el-link>
                    <span style="font-size:14px;margin-left:5px;">({{commented}})</span>
                  </div>-->
                  <el-divider></el-divider>
              </div>
              <div style="text-align:left;margin-top:40px;" v-if="browserFrom !== 'app'">
                  <h1>Account Details</h1>
                  <account-detail :view="view" @autoLogout="logoutClick"></account-detail>
              </div>
          </div>
        </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import {mapState} from 'vuex'
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ModelMyAccount from '../viewmodel/ModelMyAccount.vue'
import AccountDetail from '../../components/widgets/AccountDetail.vue'

export default {
    extends: ModelMyAccount,
    name: 'account',
    components: {
        BaseMobilePage,
        AccountDetail
    },
    data () {
      return {
        view: 'mobile'
      }
    },
    computed: {
      ...mapState('client', ['browserFrom'])
    }
}
</script>

<style>

</style>